.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.message {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #555;
}

/* Button.module.css */
.button {
  padding: 10px 20px;
  background-color: var(--lunchSpan);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* box-shadow: var(--boxShadow); */
  border: var(--black-color) 2px solid;
  transition: box-shadow 1s ease; /* Add transition for background color change */
}

.button:hover,
.button:active,
.button:focus,
.button:target,
.button:visited {
  color: var(--lunchSpan);
  background-color: var(--white-color);
  box-shadow: var(--boxShadow); /* Change background color on hover */
}

/* Additional animation example */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.button.pulse {
  animation: pulse 1s infinite; /* Apply pulse animation */
}
